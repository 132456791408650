// Default Laravel bootstrapper, installs axios
import './bootstrap';

import 'flowbite';


window.goToElement = function (divId) {
    console.log(divId)
    var scrollTo = document.getElementById(divId);
    var pos = scrollTo.getBoundingClientRect();


    window.scrollTo({ top: pos.top - 20, behavior: "smooth" });
}